:root {
    --mui-primary-main: #4070FF;
    --mui-warning-main: #FFAA00;
    --mui-success-main: #3DCC3D;
    --mui-error-main: #D01F2E;
    --mui-theme-main: #048055;
  }

.bp3-text-muted,
.bp3-form-helper-text {
    color: #d9822b !important;
}

.bp3-label {
    font-weight: 700;
}

.MuiInputBase-input {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.MuiSelect-select {
    padding: 8px 14px !important;
    padding-right: 32px !important;
}

.MuiAutocomplete-inputRoot {
    padding: 0.5px 9px !important;
}

